import styles from './index.module.css'
import BasicBlackButton from '../reusable/BasicBlackButton'
import Column from '../../Layout/Column'

function PrivacyPage() {
    return (
        <Column className={styles.container}>
            <BasicBlackButton text="OUR TERMS" />
            <div className={styles.innerContainer}>
                <p>
                    By downloading or using our apps, these terms will
                    automatically apply to you – you should make sure therefore
                    that you read them carefully before using our apps. You’re not
                    allowed to copy, or modify the apps, any part of the apps, or
                    our trademarks in any way. You’re not allowed to attempt to
                    extract the source code of the apps, and you also shouldn’t try
                    to translate the apps into other languages, or make derivative
                    versions. These apps, and all the trade marks, copyright,
                    database rights and other intellectual property rights related
                    to them, still belong to Levom.
                </p> <p>
                    Levom is committed to ensuring that our apps is
                    as useful and efficient as possible. For that reason, we
                    reserve the right to make changes to our apps or to charge for
                    those services, at any time and for any reason.We will never
                    charge you for our apps or its services without making it very
                    clear to you exactly what you’re paying for.
                </p> <p>
                    Our apps stores and processes personal data that
                    you have provided to us, in order to provide our
                    Services. It’s your responsibility to keep your phone and
                    access to our apps secure. We therefore recommend that you do
                    not jailbreak or root your phone, which is the process of
                    removing software restrictions and limitations imposed by the
                    official operating system of your device.It could make your
                    phone vulnerable to malware/viruses/malicious programs,
                    compromise your phone’s security features and it could mean
                    that our apps won’t work properly or at all.
                </p> <div><p>
                    Our apps  use third party services that declare their own
                    Terms and Conditions.
                </p> <p>
                        Link to Terms and Conditions of third party service
                        providers used by the app
                    </p> <ul>
                        <li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
                        <li><a href="https://developers.google.com/admob/terms" target="_blank" rel="noopener noreferrer">AdMob</a></li>
                        <li><a href="https://firebase.google.com/terms/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li>
                        <li><a href="https://www.facebook.com/legal/terms/plain_text_terms" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                        <li><a href="https://expo.io/terms" target="_blank" rel="noopener noreferrer">Expo</a></li>
                        <li><a href="https://amplitude.com/terms" target="_blank" rel="noopener noreferrer">Amplitude</a></li>
                    </ul></div>
                <p>
                    You should be aware that there are certain things that
                    Levom will not take responsibility for.Certain
                    functions of the app will require the app to have an active
                    internet connection.The connection can be Wi-Fi, or provided
                    by your mobile network provider, but Levom
                    cannot take responsibility for the app not working at full
                    functionality if you don’t have access to Wi-Fi, and you don’t
                    have any of your data allowance left.
                </p> <p></p> <p>
                    If you’re using the app outside of an area with Wi-Fi, you
                    should remember that your terms of the agreement with your
                    mobile network provider will still apply.As a result, you may
                    be charged by your mobile provider for the cost of data for
                    the duration of the connection while accessing the app, or
                    other third party charges.In using the app, you’re accepting
                    responsibility for any such charges, including roaming data
                    charges if you use the app outside of your home territory
                    (i.e.region or country) without turning off data roaming.If
                    you are not the bill payer for the device on which you’re
                    using the app, please be aware that we assume that you have
                    received permission from the bill payer for using the app.
                </p> <p>
                    Along the same lines, Levom cannot always take
                    responsibility for the way you use the app i.e.You need to
                    make sure that your device stays charged – if it runs out of
                    battery and you can’t turn it on to avail the Service,
                    Levom cannot accept responsibility.
                </p> <p>
                    With respect to Levom’s responsibility for your
                    use of the app, when you’re using the app, it’s important to
                    bear in mind that although we endeavour to ensure that it is
                    updated and correct at all times, we do rely on third parties
                    to provide information to us so that we can make it available
                    to you.Levom accepts no liability for any
                    loss, direct or indirect, you experience as a result of
                    relying wholly on this functionality of the app.
                </p> <p>
                    At some point, we may wish to update the app.The app is
                    currently available on Android &amp; iOS – the requirements for
                    both systems(and for any additional systems we
                    decide to extend the availability of the app to) may change,
                    and you’ll need to download the updates if you want to keep
                    using the app.Levom does not promise that it
                    will always update the app so that it is relevant to you
                    and/or works with the Android &amp; iOS version that you have
                    installed on your device.However, you promise to always
                    accept updates to the application when offered to you, We may
                    also wish to stop providing the app, and may terminate use of
                    it at any time without giving notice of termination to you.
                    Unless we tell you otherwise, upon any termination, (a) the
                    rights and licenses granted to you in these terms will end;
                    (b) you must stop using the app, and (if needed) delete it
                    from your device.
                </p> <p><strong>Changes to This Terms and Conditions</strong></p> <p>
                    We may update our Terms and Conditions
                    from time to time.Thus, you are advised to review this page
                    periodically for any changes.We will
                    notify you of any changes by posting the new Terms and
                    Conditions on this page.
                </p> <p>
                    These terms and conditions are effective as of 2021-09-04
                </p> <p><strong>Contact Us</strong></p> <p>
                    If you have any questions or suggestions about our
                    Terms and Conditions, do not hesitate to contact us
                    at levom.apps @gmail.com.
                </p> <p>This Terms and Conditions page was generated by <a href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank" rel="noopener noreferrer">App Privacy Policy Generator</a></p>
            </div>
        </Column>
    );
}

export default PrivacyPage;
