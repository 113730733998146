import Column from "../../../Layout/Column"
import Row from "../../../Layout/Row"
import BreakLine from "../../../shell/BreakLine"
import Item from "./Item"
import YoutubeIcon from './youtube.png'
import EarthIcon from './earth.png'
import BagIcon from './bag.png'
import WierdIcon from './wierd.png'
import CalcIcon from './calc.png'
import RockIcon from './rock.png'
import styles from './index.module.css'

const width = window.innerWidth

export default () => {
    const data = [
        { icon: YoutubeIcon, text: "YOUTUBE GUDIENCE VIDEOS FOR EACH EXERCISES ON THE MATH APP" },
        { icon: EarthIcon, text: "MULTIPLE LANGUAGES FOR EVERY APP" },
        { icon: BagIcon, text: "A LOT OF TOOLS FOR OUR USERS INCLUDING BRAIN MUSCLE APPS AND GAMING APPS" },
        { icon: WierdIcon, text: "OUR APPS MAKE YOU PLAY & THING WITHOUT EVEN NOTICING ON THE SAME TIME" },
        { icon: CalcIcon, text: "DIFFERENT APPS FOR EVERY PURPOSE. YES - EVEN LEARNING IS FUN WITH US!" },
    ]
    return (
        <Column>
            <Row className={styles.row} justifyContent="space-between" alignItems="center">
                <Column>
                    <span className={styles.title}>Why choose us?</span>
                    <span>We are the best at making kids</span>
                    <span>enjoy learning</span>
                </Column>
                <img className={styles.img} src={RockIcon} width={width * 0.15} height={width * 0.225} />
            </Row>
            <br/>
            <BreakLine />
            {data.map(({ icon, text }) => <Item icon={icon} text={text} />)}
        </Column>
    )
}