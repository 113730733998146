import { NavLink } from 'react-router-dom';
import Column from '../../Layout/Column';
import Row from '../../Layout/Row';
import styles from './index.module.css'
import LearnLineIcon from './learnline.png'


function Header() {
    return (
        <div className={styles.container}>
            <NavLink className={styles.levomNavlink} to="/">
                <span className={styles.LevomText}>LEVOM</span>
                <span className={styles.LevomTmText}>TM</span>
            </NavLink>
            <div className={styles.BelowNavbar}>
                <span className={styles.BelowNavbarText1}>BEST EDUCATIONAL APPS AND GAMES</span><br />
                <span className={styles.BelowNavbarText2}>A MUCH BETTER WAY TO</span><br />
                <Column>
                    <Row>
                        <span className={styles.BelowNavbarText3}>LEARN
                            <img className={styles.learnImage} src={LearnLineIcon} />
                        </span>
                        <span className={styles.threedots}>...</span>
                    </Row>
                </Column>
            </div>
        </div>
    );
}

export default Header;
