import Row from "../../../Layout/Row"
import Appstore from './appstore.png'
import Google from './google.png'
import Youtube from './youtube.png'
import Instagram from './instagram.png'
import Facebook from './facebook.png'
import Whatsapp from './whatsapp.png'
import styles from './index.module.css'


const width = window.innerWidth

export default () => {
    const data = [
        { icon: Instagram, url: "https://www.instagram.com/levom.apps/" },
        // {icon: Youtube, url: ""},
        // {icon: Whatsapp, url: ""},
        { icon: Appstore, url: "https://apps.apple.com/il/developer/omer-levi/id1480878762" },
        { icon: Google, url: "https://play.google.com/store/apps/developer?id=Levom&hl=en" },
        { icon: Facebook, url: "https://www.facebook.com/levom.apps/" },
    ]

    return (
        <Row className={styles.container}>
            {data.map(({ icon, url }) => <img onClick={() => window.open(url)} className={styles.img} src={icon} />)}
        </Row>
    )
}