import * as Yup from 'yup'

export default Yup.object({
    firstName: Yup.string()
        .min(2, "Please enter valid name")
        .max(25, "Please enter no more than 25 characters")
        .required("Required"),
    lastName: Yup.string()
        .min(2, "Please enter valid last name")
        .max(25, "Please enter no more than 25 characters")
        .required("Required"),
    email: Yup.string()
        .email("Please enter a valid email")
        .required("Required"),
    message: Yup.string()
        .min(10, "Please enter some data")
        .max(2000, "Please enter no more than 2000 characters")
        .required("Required"),
})