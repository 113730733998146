import React from 'react';
import styles from './App.module.css';
import HomePage from './pages/home';
import Router from './router';

function App() {
	return (
		<div className={styles.App}>
			<div style={{ width: Math.min(500, window.innerWidth) }} className={styles.innerApp}>
				<div style={{ width: '86%', alignSelf: 'center' }}>
					<Router />
				</div>
			</div>
		</div>
	);
}

export default App;
