import "react-responsive-carousel/lib/styles/carousel.min.css"
import "@fontsource/oxanium"; // Defaults to weight 400.
import Carousel from './Carousel';
import styles from './index.module.css'; // Import css modules stylesheet as styles
import Apps from './Apps';
import WhyUs from './WhyUs';
import BreakLine from '../../shell/BreakLine';
import BasicBlackButton from '../reusable/BasicBlackButton'

function HomePage() {
    return (
        <div className={styles.App}>
            <BasicBlackButton text="Pick & Play" />
            <br />
            <Carousel />
            <br />
            <BreakLine />
            <BasicBlackButton text="Our games & apps" />
            <br />
            <Apps />
            <br />
            <br />
            <WhyUs />
            <br />
        </div>
    );
}

export default HomePage;
