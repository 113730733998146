import Row from "../../../../Layout/Row"
import styles from './index.module.css'

const width = window.innerWidth

export default ({ icon, text }) => {
    return (
        <Row justifyContent="space-between" alignItem="center" className={styles.container}>
            <img className={styles.img} src={icon} />
            <Row justifyContent="flex-start" alignItems="center" className={styles.textContainer}>
                <span className={styles.text}>{text}</span>
            </Row>
        </Row>
    )
}