import styles from './index.module.css'


function BasicBlackButton({ text }) {
    return (
        <div className={styles.container}>
            <span className={styles.text}>{text}</span>
        </div>
    );
}

export default BasicBlackButton;
