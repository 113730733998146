import Column from "../../../Layout/Column"
import Row from "../../../Layout/Row"
import AppDisplay from "./AppDisplay"
import styles from './index.module.css'
import MathIcon from './math.png'
import TowerIcon from './tower.png'
import BoxIcon from './box.png'
import TriviaIcon from './trivia.png'
import flappyIcon from './flappy.png'
import EnglishIcon from './english.png'


export default () => {
    return (
        <Column className={styles.container}>
            <Row className={styles.row} justifyContent="space-around">
                <AppDisplay
                    googleUrl={"https://play.google.com/store/apps/details?id=com.math.learnmath"}
                    appleUrl={"https://apps.apple.com/us/app/id1533830942"}
                    icon={MathIcon} name="LEARN MATH" />
                <AppDisplay
                    googleUrl={"https://play.google.com/store/apps/details?id=com.omer.myfirstgame"}
                    icon={BoxIcon} name="BOX SLIDE" />
            </Row>
            <br />
            <Row className={styles.row}  justifyContent="space-around">
                <AppDisplay
                    googleUrl={"https://play.google.com/store/apps/details?id=com.trivia.triviaquiz"}
                    appleUrl={"https://apps.apple.com/us/app/id1518856808"}
                    icon={TriviaIcon} name="TRIVIA WORLD" />
                <AppDisplay
                    googleUrl={"https://play.google.com/store/apps/details?id=com.omer.omerlevi"}
                    appleUrl={"https://apps.apple.com/us/app/the-tower-play/id1480878763"}
                    icon={TowerIcon} name="THE TOWER" />
            </Row>
            <br />
            <Row className={styles.row}  justifyContent="space-around">
                <AppDisplay
                    googleUrl={"https://play.google.com/store/apps/details?id=com.levom.translator"}
                    appleUrl={"https://apps.apple.com/us/app/id1503414769?l=iw&ls=1"}
                    icon={EnglishIcon} name="LEARN ENGLISH" />
            </Row>
        </Column>
    )
}