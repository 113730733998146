import axios from 'axios'

const baseUrl = ""

export const get = async (url) => {
      const { data } = await axios.get(`${baseUrl}/${url}`)
      return data
}

export const post = async (url, body) => {
      const { data } = await axios.post(`${baseUrl}/${url}`, body)
      return data
}