import Column from "../../../../Layout/Column"
import styles from './index.module.css'
import Google from './google.png'
import Apple from './apple.png'

const width = window.innerWidth

export default ({ icon, name, googleUrl, appleUrl }) => {
    const openUrl = (url) => window.open(url)

    return (
        <Column className={styles.container} justifyContent="flex-start" alignItems="center" >
            <img className={styles.img} src={icon} />
            <span className={styles.text}>{name}</span>
            <Column>
                {googleUrl ? <img onClick={() => openUrl(googleUrl)} className={styles.storeImg} src={Google} /> : null}
                {appleUrl ? <img onClick={() => openUrl(appleUrl)} className={styles.storeImg} src={Apple} /> : null}
            </Column>
        </Column>
    )
}