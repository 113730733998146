import Column from "../../../Layout/Column"
import Row from "../../../Layout/Row"
import PeopleIcon from './people.svg'
import styles from './index.module.css'

const width = window.innerWidth

export default () => {
    return (
        <Column className={styles.container}>
            <img className={styles.img} src={PeopleIcon} />
            <span className={styles.text}>More than 250,000 users using our apps!</span><br />
        </Column>
    )
}