import Column from "../../Layout/Column"
import Row from "../../Layout/Row"
import CubeIcon from './cube.png'
import styles from './index.module.css'
import SocialMedia from "./SocialMedia"
import Subscribers from "./Subscribers"
import FooterBlueIcon from './footerblue1.svg'
import Links from './Links'
import BreakLine from "../../shell/BreakLine"

const width = window.innerWidth

export default () => {
    return (
        <Column className={styles.container}>
            <BreakLine />
            <br />
            <Row justifyContent="space-between" alignItem="center" className={styles.row1}>
                <img src={CubeIcon} className={styles.image} />
                <div className={styles.row1TextsContainer}>
                    <span className={styles.text}>Contact us on social media!</span><br />
                    <span className={styles.text}>Let us know if we are good as</span><br />
                    <span className={styles.text}>we think we are.</span><br />
                </div>
            </Row>
            <br />
            <SocialMedia />
            <Subscribers />
            <Links />
            <div className={styles.allRightsContainer}>
                <div className={styles.allRightsInnerContainer}>
                    <span className={styles.allRights}>ALL RIGHTS RESERVED TO LEVOM</span>
                    <span className={styles.allRightsTm}>TM</span>
                </div>
            </div>
            <br />
            {/* <img className={styles.footerIcon} src={FooterBlueIcon} width={width * 0.25} height={width * 0.5} /> */}
        </Column>
    )
}