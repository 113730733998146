import styles from './index.module.css'
import BasicBlackButton from '../reusable/BasicBlackButton'
import Column from '../../Layout/Column'
import ContactUsForm from './form';

function PrivacyPage() {
    return (
        <Column className={styles.container}>
            <BasicBlackButton text="REACH OUT" />
            <ContactUsForm />
        </Column>
    );
}

export default PrivacyPage;
