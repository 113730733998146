import React from 'react'
import { useFormik } from 'formik'
import ValidationScheme from './ValidationScheme';
import styles from './Form.module.css'
import FormField from './FormField';
import * as http from '../../../HttpClient';

export default () => {
  const { handleBlur, handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      message: ""
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: ValidationScheme,
    onSubmit: async (values, {resetForm}) => {
      await http.post("api/contact", values)
      alert("Thank you " + values.firstName);
    },
  });

  return (
    <form className={styles.container} >
      <FormField
        required={true}
        placeholder="First Name"
        name="firstName"
        handleBlur={handleBlur}
        handleChange={handleChange}
        value={values.firstName}
        error={errors.firstName} />
      <FormField
        required={true}
        placeholder="Last Name"
        name="lastName"
        handleBlur={handleBlur}
        handleChange={handleChange}
        value={values.lastName}
        error={errors.lastName} />
      <FormField
        required={true}
        placeholder="Email"
        name="email"
        handleBlur={handleBlur}
        handleChange={handleChange}
        value={values.email}
        error={errors.email} />
      <FormField
        textArea={true}
        required={true}
        placeholder="Message"
        name="message"
        handleBlur={handleBlur}
        handleChange={handleChange}
        value={values.message}
        error={errors.message} />
      <br />
      <button type="button" onClick={handleSubmit} className={styles.submitBtn}>Submit</button>
    </form>
  )
}