import { Carousel } from 'react-responsive-carousel'
import styles from './styles.module.css'

const width = 400
const height = 300

const images = [
    {
        original: `https://picsum.photos/id/1018/${width}/${height}/`,
    },
    {
        original: `https://picsum.photos/id/1015/${width}/${height}/`,
    },
    {
        original: `https://picsum.photos/id/1019/${width}/${height}/`,
    },
];

export default () => {
    return (
        <Carousel autoPlay className='Carousel' showStatus={false} infiniteLoop showThumbs={false}>
            <div>
                <img src={images[0].original} />
            </div>
            <div>
                <img src={images[1].original} />
            </div>
            <div>
                <img src={images[2].original} />
            </div>
        </Carousel>
    )
}