import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Nav from '../shell/navbar/Navbar';
import Landing from '../pages/home'
import Privacy from '../pages/privacy'
import Support from '../pages/support'
import Terms from '../pages/terms'
import Footer from '../pages/Footer';
import Header from '../pages/Header';
import ScrollToTop from './ScrollToTop';

export default function ApplicationRouter() {
    return (
        <BrowserRouter>
            <Header />
            <ScrollToTop />
            {/* <Nav /> */}
            <Routes >
                <Route element={<Landing />} path="/" />
                <Route element={<Privacy />} path="/privacy" />
                <Route element={<Support />} path="/support" />
                <Route element={<Terms />} path="/terms" />
                {/* <Route component={Page404} /> */}
            </Routes >
            <Footer />
        </BrowserRouter>
    )
}