import React from 'react';
import { NavLink } from 'react-router-dom'
import Row from '../../../Layout/Row';
import styles from './styles.module.css';

const Footer = () => {
  return (
    <Row className={styles.container}>
      <div className={styles.footerLinkContainer}>
        <span className={styles.linkDot}></span>
        <NavLink className={styles.footerLink} to="/"><span className={styles.linkText}>Home</span></NavLink>
      </div>
      <div className={styles.footerLinkContainer}>
        <span className={styles.linkDot}></span>
        <NavLink className={styles.footerLink} to="/privacy"><span className={styles.linkText}>Privacy</span></NavLink>
      </div>
      <div className={styles.footerLinkContainer}>
        <span className={styles.linkDot}></span>
        <NavLink className={styles.footerLink} to="/terms"><span className={styles.linkText}>Terms</span></NavLink>
      </div>
      <div className={styles.footerLinkContainer}>
        <span className={styles.linkDot}></span>
        <NavLink className={styles.footerLink} to="/support"><span className={styles.linkText}>Contact Us</span></NavLink>
      </div>
    </Row>
  )
}

export default Footer