import styles from './index.module.css'
import BasicBlackButton from '../reusable/BasicBlackButton'
import Column from '../../Layout/Column'

function PrivacyPage() {
    return (
        <Column className={styles.container}>
            <BasicBlackButton text="PRIVACY POLICY" />
            <div className={styles.innerContainer}>
                <p>
                    Levom building our apps as
                    an Ad Supported apps. These SERVICES are provided by
                    Levom at no cost and are intended for use as
                    is.
                </p> <p>
                    This page is used to inform visitors regarding our
                    policies with the collection, use, and disclosure of Personal
                    Information if anyone decided to use our Services.
                </p> <p>
                    If you choose to use our Services, then you agree to
                    the collection and use of information in relation to this
                    policy. The Personal Information that we collect is
                    used for providing and improving the Services. We will not use or share your information with
                    anyone except as described in this Privacy Policy.
                </p> <p>
                    The terms used in this Privacy Policy have the same meanings
                    as in our Terms and Conditions, which is accessible at
                    levomapps.com/terms unless otherwise defined in this Privacy Policy.
                </p> <p><strong>Information Collection and Use</strong></p> <p>
                    For a better experience, while using our Services, we
                    may require you to provide us with certain personally
                    identifiable information, including but not limited to Email, Name, Birth date. The information that
                    we request will be retained by us and used as described in this privacy policy.
                </p> <div><p>
                    The app does use third party services that may collect
                    information used to identify you.
                </p> <p>
                        Link to privacy policy of third party services providers used
                        by the app
                    </p> <ul>
                        <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
                        <li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener noreferrer">AdMob</a></li>
                        <li><a href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li>
                        <li><a href="https://www.facebook.com/about/privacy/update/printable" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                        <li><a href="https://expo.io/privacy" target="_blank" rel="noopener noreferrer">Expo</a></li>
                        <li><a href="https://amplitude.com/privacy" target="_blank" rel="noopener noreferrer">Amplitude</a></li>
                    </ul></div> <p><strong>Log Data</strong></p> <p>
                    We want to inform you that whenever you
                    use our Services, in a case of an error in the app
                    we collect data and information (through third party
                    products) on your phone called Log Data. This Log Data may
                    include information such as your device Internet Protocol
                    (“IP”) address, device name, operating system version, the
                    configuration of the app when utilizing our Services,
                    the time and date of your use of the Services, and other
                    statistics.
                </p> <p><strong>Cookies</strong></p> <p>
                    Cookies are files with a small amount of data that are
                    commonly used as anonymous unique identifiers. These are sent
                    to your browser from the websites that you visit and are
                    stored on your device's internal memory.
                </p> <p>
                    These Services does not use these “cookies” explicitly. However,
                    our apps may use third party code and libraries that use
                    “cookies” to collect information and improve their services.
                    You have the option to either accept or refuse these cookies
                    and know when a cookie is being sent to your device. If you
                    choose to refuse our cookies, you may not be able to use some
                    portions of this Services.
                </p> <p><strong>Service Providers</strong></p> <p>
                    We may employ third-party companies and
                    individuals due to the following reasons:
                </p> <ul><li>To facilitate our Services;</li> <li>To provide the Services on our behalf;</li> <li>To perform Services-related services; or</li> <li>To assist us in analyzing how our Services are used.</li></ul> <p>
                    We want to inform users of this Services
                    that these third parties have access to your Personal
                    Information. The reason is to perform the tasks assigned to
                    them on our behalf. However, they are obligated not to
                    disclose or use the information for any other purpose.
                </p> <p><strong>Security</strong></p> <p>
                    We value your trust in providing us your
                    Personal Information, thus we are striving to use commercially
                    acceptable means of protecting it. But remember that no method
                    of transmission over the internet, or method of electronic
                    storage is 100% secure and reliable, and we cannot
                    guarantee its absolute security.
                </p> <p><strong>Links to Other Sites</strong></p> <p>
                    These Services may contain links to other sites. If you click on
                    a third-party link, you will be directed to that site. Note
                    that these external sites are not operated by us.
                    Therefore, we strongly advise you to review the
                    Privacy Policy of these websites. We have
                    no control over and assume no responsibility for the content,
                    privacy policies, or practices of any third-party sites or
                    services.
                </p> <p><strong>Children’s Privacy</strong></p> <p>
                    Levom does not knowingly collect or store any personal information from children under 13 or under the relevant age threshold
                    in other jurisdictions where the child is located. However, we may collect technical information
                    required for delivering the service and for the support of our internal operations from all users of our Properties.
                </p> <p><strong>Changes to This Privacy Policy</strong></p> <p>
                    We may update our Privacy Policy from
                    time to time. Thus, you are advised to review this page
                    periodically for any changes. We will
                    notify you of any changes by posting the new Privacy Policy on
                    this page.
                </p> <p>This policy is effective as of 2021-09-04</p> <p><strong>Contact Us</strong></p> <p>
                    If you have any questions or suggestions about our
                    Privacy Policy, do not hesitate to contact us at levom.apps@gmail.com.
                </p> <p>This privacy policy page was created at <a href="https://privacypolicytemplate.net" target="_blank" rel="noopener noreferrer">privacypolicytemplate.net </a>and modified/generated by <a href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank" rel="noopener noreferrer">App Privacy Policy Generator</a></p>
            </div>
        </Column>
    );
}

export default PrivacyPage;
