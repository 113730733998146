import React from 'react'
import styles from './Form.module.css'

export default (props) => {
    return (
        <div className={styles.formField}>
            <div className={styles.inputFormField}>
                {props.textArea ?
                    <textarea rows="10"
                        className={props.error ? styles.inputError : ""}
                        placeholder={props.placeholder}
                        name={props.name}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        values={props.value}
                    />
                    :
                    <input
                        className={props.error ? styles.inputError : ""}
                        placeholder={props.placeholder}
                        name={props.name}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        values={props.value}
                    />
                }
                {props.required ? <span className={styles.asterisk}>*</span> : null}
            </div>
            {/* <span className={styles.required}>{props.error ? props.error : null}</span> */}
        </div>
    )
}